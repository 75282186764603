<template>
	<div class="w-full h-min">
		<svg width="173" class="w-full" height="37" viewBox="0 0 173 37" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_44_10142)">
				<path
					d="M32.9105 0.0761719H4.06874C1.87393 0.0761719 0.0935059 1.86293 0.0935059 4.06554V33.0098C0.0935059 35.2125 1.87393 36.9992 4.06874 36.9992H32.9105C35.1053 36.9992 36.8857 35.2125 36.8857 33.0098V4.06554C36.8857 1.86293 35.1053 0.0761719 32.9105 0.0761719Z"
					fill="#016A6D" />
				<path
					d="M20.7848 9.53369H25.3488V17.2637C23.8777 17.8925 22.353 18.3401 20.7848 18.6008V9.53369ZM11.1451 9.53369H15.7349V18.8117C14.1646 18.6843 12.6241 18.3739 11.1451 17.8964V9.53369ZM15.7329 27.5425H11.1431V20.8532C12.6321 21.3326 14.1646 21.6391 15.7329 21.7724V27.5425ZM25.3468 27.5425H20.7828V21.6032C22.357 21.3585 23.8876 20.9387 25.3468 20.3537V27.5445V27.5425Z"
					fill="white" />
				<path
					d="M25.3144 19.121C23.871 19.7338 22.3423 20.1795 20.7503 20.4381C19.6995 20.6093 18.619 20.6988 17.5206 20.6988C16.9059 20.6988 16.2992 20.671 15.7004 20.6172C14.1104 20.474 12.5738 20.1437 11.1106 19.6482V19.0633C12.5798 19.5169 14.1144 19.8134 15.7004 19.9348C16.2516 19.9785 16.8068 20.0004 17.3679 20.0004C18.5178 20.0004 19.6479 19.9089 20.7503 19.7318C22.3364 19.4791 23.8651 19.0494 25.3144 18.4644C26.2681 18.0804 27.1899 17.6287 28.0703 17.1134C28.1416 17.2626 28.2051 17.4178 28.2566 17.575C27.3248 18.1679 26.3414 18.6872 25.3144 19.123V19.121ZM29.4165 17.4595C29.3788 17.8396 29.2896 18.2216 29.1429 18.5937C29.0953 18.7131 29.0417 18.8304 28.9862 18.9379C29.0259 18.3569 28.9406 17.7819 28.7463 17.2487C28.6948 17.1014 28.6313 16.9581 28.562 16.8169C28.2725 16.2379 27.8442 15.7245 27.305 15.3325C27.5132 15.3823 27.7214 15.444 27.9236 15.5255C28.2249 15.6469 28.5025 15.7961 28.7563 15.9752C28.9288 16.0966 29.0696 16.2498 29.1766 16.4209C29.2559 16.5443 29.3154 16.6776 29.357 16.8169C29.4185 17.0218 29.4403 17.2387 29.4205 17.4576L29.4165 17.4595Z"
					fill="#FF7617" />
				<path
					d="M111.562 8.48145C110.156 8.48145 108.967 9.64714 108.967 11.0579C108.967 12.4691 110.156 13.6621 111.562 13.6621C112.967 13.6621 114.157 12.4691 114.157 11.0579C114.157 9.64714 112.967 8.48145 111.562 8.48145Z"
					fill="#016A6D" />
				<path d="M113.805 28.3405V14.7754H109.318V28.3405H113.805Z" fill="#016A6D" />
				<path
					d="M61.5814 9.31982V28.311H56.7684V21.0117H51.4418V28.311H46.6035V9.31982H51.4418V16.3464H56.7684V9.31982H61.5814Z"
					fill="#016A6D" />
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M63.3428 21.5569C63.3428 17.5143 66.5606 14.395 70.5612 14.395C74.5619 14.395 77.7794 17.5143 77.7794 21.5569C77.7794 25.5994 74.5895 28.7188 70.5612 28.7188C66.5333 28.7188 63.3428 25.5994 63.3428 21.5569ZM73.2908 21.5569C73.2908 19.8735 72.1292 18.7354 70.5612 18.7354C68.9934 18.7354 67.857 19.8756 67.857 21.5569C67.857 23.2379 69.0187 24.3784 70.5612 24.3784C72.1039 24.3784 73.2908 23.2379 73.2908 21.5569Z"
					fill="#016A6D" />
				<path
					d="M84.803 28.7208C87.5617 28.7208 90.643 27.5551 90.643 24.1631C90.643 20.809 87.8085 20.1532 85.8255 19.6944C84.7586 19.4473 83.9376 19.2576 83.9376 18.7354C83.9376 18.3299 84.3163 18.0849 84.9372 18.0849C85.3273 18.0849 85.66 18.1921 85.942 18.4C86.2088 18.5959 86.4303 18.8814 86.6143 19.2506L90.3985 17.542C89.2917 15.3178 87.2627 14.395 84.8298 14.395C82.0727 14.395 79.3961 15.8863 79.3961 18.9528C79.3961 22.0676 82.1701 22.7857 84.1201 23.2906C85.1771 23.5641 85.9919 23.7749 85.9919 24.2707C85.9919 24.7587 85.5603 25.0038 84.7754 25.0038C83.7207 25.0038 83.0178 24.5137 82.6668 23.5105L78.7461 25.4913C79.8276 27.798 82.0436 28.7208 84.803 28.7208Z"
					fill="#016A6D" />
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M107.372 21.557C107.372 25.6268 104.64 28.7189 101.018 28.7189C99.2884 28.7189 98.0444 28.1211 97.1797 27.0903V33.7646H92.6926V14.7734H97.1797V16.0216C98.0444 14.9908 99.2884 14.3931 101.018 14.3931C104.64 14.3931 107.372 17.4873 107.372 21.557ZM102.857 21.557C102.857 19.7935 101.696 18.6002 100.018 18.6002C98.341 18.6002 97.1797 19.7935 97.1797 21.557C97.1797 23.3205 98.341 24.5138 100.018 24.5138C101.696 24.5138 102.857 23.3205 102.857 21.557Z"
					fill="#016A6D" />
				<path
					d="M122.182 19.0883V23.0226C122.182 24.2986 123.181 24.3786 124.993 24.2709V28.341C119.477 29.0188 117.692 27.3102 117.692 23.1328V19.0903H115.666V14.7755H117.692V12.3339L122.179 10.978V14.7755H124.991V19.0903L122.182 19.0883Z"
					fill="#016A6D" />
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M141.075 28.3404V14.7748L136.589 14.7773V16.0255C135.723 14.9947 134.48 14.397 132.75 14.397C129.128 14.397 126.396 17.489 126.396 21.5588C126.396 25.6286 129.128 28.7207 132.75 28.7207C134.48 28.7207 135.723 28.123 136.589 27.0921V28.3404H141.075ZM133.747 18.6C135.425 18.6 136.586 19.7933 136.586 21.5567C136.586 23.3202 135.425 24.5136 133.747 24.5136C132.07 24.5136 130.909 23.3202 130.909 21.5567C130.909 19.7933 132.07 18.6 133.747 18.6Z"
					fill="#016A6D" />
				<path d="M143.46 8.53418H147.947V28.3405H143.46V8.53418Z" fill="#016A6D" />
				<path
					d="M152.787 8.48145C151.381 8.48145 150.193 9.64714 150.193 11.0579C150.193 12.4691 151.381 13.6621 152.787 13.6621C154.193 13.6621 155.382 12.4691 155.382 11.0579C155.382 9.64714 154.193 8.48145 152.787 8.48145Z"
					fill="#016A6D" />
				<path d="M155.031 28.3405V14.7754H150.544V28.3405H155.031Z" fill="#016A6D" />
				<path
					d="M163.196 19.0883V23.0226C163.196 24.2986 164.196 24.3786 166.008 24.2709V28.341C160.492 29.0188 158.707 27.3102 158.707 23.1328V19.0903H156.68V14.7755H158.707V12.3339L163.194 10.978V14.7755H166.006V19.0903L163.196 19.0883Z"
					fill="#016A6D" />
				<path
					d="M170.333 8.48145C168.927 8.48145 167.738 9.64714 167.738 11.0579C167.738 12.4691 168.927 13.6621 170.333 13.6621C171.738 13.6621 172.928 12.4691 172.928 11.0579C172.928 9.64714 171.738 8.48145 170.333 8.48145Z"
					fill="#016A6D" />
				<path d="M172.576 28.3405V14.7754H168.089V28.3405H172.576Z" fill="#016A6D" />
			</g>
			<defs>
				<clipPath id="clip0_44_10142">
					<rect width="173" height="37" fill="white" />
				</clipPath>
			</defs>
		</svg>

	</div>
</template>
